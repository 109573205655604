import React, { useState } from "react"
import axios from "axios"
import Recaptcha from "react-recaptcha"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

import useForm from "../Hooks/useForm"
import validate from "../validation/Contact"

import Message from "../../Message"
import Icon from "../../Icon"
import Feedback from "../Feedback"
import Heading from "../../Layout/Heading"

const API = process.env.API
const recaptchaKey = process.env.GOOGLE_RECAPTCHA_KEY
let recaptchaInstance

const ContactForm = ({ show, setShow, dealership }) => {
  const [message, setMessage] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    handleChange,
    handleSubmit,
    handleReCaptcha,
    resetReCaptcha,
    values,
    errors,
  } = useForm(submit, validate)

  function submit() {
    setMessage({})
    setIsSubmitting(true)
    axios
      .post(`${API}/form/${dealership}/contact`, {
        name: values.name,
        phone: values.phone,
        email: values.email,
        enquiry: values.enquiry,
        reCaptcha: values.reCaptcha,
      })
      .then(() => {
        setIsSubmitting(false)
        setMessage({
          type: "success",
          text: "Your enquiry has been sent",
        })
        resetReCaptcha(recaptchaInstance)
      })
      .catch(() => {
        setIsSubmitting(false)
        setMessage({
          type: "danger",
          text: "Your enquiry failed to send",
        })
        resetReCaptcha(recaptchaInstance)
      })
  }

  const closeModal = () => {
    setShow(false)
    setMessage({})
  }

  return (
    <>
      <Modal centered show={show} onHide={() => closeModal()} size="lg">
        <Modal.Body>
          <Heading title="General Enquiry" />
          <form onSubmit={handleSubmit} noValidate>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <input
                    onChange={handleChange}
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    aria-label="Full Name"
                  />
                  <Feedback message={errors.name} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <input
                    onChange={handleChange}
                    name="phone"
                    className="form-control"
                    placeholder="Phone"
                    aria-label="Phone Number"
                  />
                  <Feedback message={errors.phone} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <input
                    onChange={handleChange}
                    name="email"
                    className="form-control"
                    placeholder="E-Mail"
                    aria-label="Email Address"
                  />
                  <Feedback message={errors.email} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <textarea
                    onChange={handleChange}
                    name="enquiry"
                    className="form-control"
                    rows="3"
                    placeholder="Enquiry"
                    aria-label="Enquiry"
                  />
                  <Feedback message={errors.enquiry} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col text-left">
                <div className="form-group">
                  <Recaptcha
                    ref={(e) => (recaptchaInstance = e)}
                    sitekey={recaptchaKey}
                    render="explicit"
                    onloadCallback={() => {}}
                    verifyCallback={(response) => {
                      handleReCaptcha(response)
                    }}
                  />
                  <Feedback message={errors.reCaptcha} />
                </div>
                <Message type={message.type} message={message.text} />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => closeModal()}>
            Cancel
          </Button>
          {isSubmitting ? (
            <Button type="submit" disabled bsPrefix="smg">
              Sending <Icon prefix="fas" name="spinner" spin />
            </Button>
          ) : (
            <Button type="submit" onClick={handleSubmit} bsPrefix="smg">
              Send <Icon prefix="fas" name="paper-plane" />
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ContactForm
