import React, { useState } from "react"
import Button from "react-bootstrap/Button"

import Layout from "../components/Layout"
import Heading from "../components/Layout/Heading"
import SubHeading from "../components/Layout/SubHeading"
import GoogleMaps from "../components/GoogleMaps"
import Icon from "../components/Icon"
import SEO from "../components/SEO"
import ContactForm from "../components/forms/Contact"

const Contact = (props) => {
  const [dealership, setDealership] = useState("")
  const [showModal, setShowModal] = useState(false)

  const showContactForm = (dealership) => {
    setDealership(dealership)
    setShowModal(true)
  }

  return (
    <Layout location={props.location}>
      <ContactForm
        show={showModal}
        setShow={setShowModal}
        dealership={dealership}
      />
      <SEO
        title="Contact Us"
        description="Get in touch with us, we have 2 great locations on the Princes Highway"
      />
      <Heading title="Contact Us" />
      <div className="row">
        <div className="col-12 col-md-6">
          <SubHeading subHeading="Solomon Motors City" />
          <div className="row google-maps-container">
            <div className="col-12">
              <GoogleMaps
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                mapCenter={{ lat: -34.908845, lng: 150.60355 }}
                isMarkerShown
                markerCenter={{ lat: -34.908845, lng: 150.60355 }}
                zoom={17}
              />
            </div>
          </div>
          <div className="row buttons-container">
            <div className="col-12">
              <ul className="nav justify-content-center">
                <li className="nav-item mx-2 mb-3">
                  <a href="tel:0244230222">
                    <Button variant="primary" bsPrefix="smg">
                      Phone <Icon prefix="fas" name="phone" />
                    </Button>
                  </a>
                </li>
                <li className="nav-item mx-2 mb-3">
                  <a
                    href="https://www.google.com/maps/place/Solomon+Motor+Group/@-34.8910658,150.5873489,14z/data=!4m8!1m2!2m1!1ssolomon+motors+city!3m4!1s0x6ad661b6ef2d6dbf:0x33917e13647bf0c8!8m2!3d-34.9088274!4d150.6035471"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="primary" bsPrefix="smg">
                      Get Directions <Icon prefix="fas" name="map-marker-alt" />
                    </Button>
                  </a>
                </li>
                <li className="nav-item mx-2 mb-3">
                  <Button
                    variant="primary"
                    bsPrefix="smg"
                    onClick={() => showContactForm("southnowra")}
                  >
                    General Enquiry <Icon prefix="fas" name="envelope" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 text-center">
              <p className="details-heading">Contact Details</p>
              <p className="details-subheading">Phone</p>
              <p>(02) 4423 0222</p>
              <p className="details-subheading">Address</p>
              <p>Cnr Princes Highway &amp; Quinns Lane, South Nowra, NSW</p>
            </div>
            <div className="col-12 col-lg-6 text-center">
              <p className="details-heading">Trading Hours</p>
              <p className="details-subheading">Monday - Friday</p>
              <p>8:30am - 5:00pm</p>
              <p className="details-subheading">Saturday</p>
              <p>8:30am - 2:30pm</p>
              <p className="details-subheading">Sunday</p>
              <p>Closed</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <SubHeading subHeading="Solomons Mid City Autos" />
          <div className="row google-maps-container">
            <div className="col-12">
              <GoogleMaps
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                mapCenter={{ lat: -34.873356, lng: 150.606204 }}
                isMarkerShown
                markerCenter={{ lat: -34.873356, lng: 150.606204 }}
                zoom={17}
              />
            </div>
          </div>
          <div className="row buttons-container">
            <div className="col-12">
              <ul className="nav justify-content-center">
                <li className="nav-item mx-2 mb-3">
                  <a href="tel:0244213666">
                    <Button variant="primary" bsPrefix="smg">
                      Phone <Icon prefix="fas" name="phone" />
                    </Button>
                  </a>
                </li>
                <li className="nav-item mx-2 mb-3">
                  <a
                    href="https://www.google.com/maps/place/Solomons+Mid+City+Autos/@-34.8733065,150.603982,17z/data=!3m1!4b1!4m5!3m4!1s0x6b148021d304f71b:0x3928d42a703bd9e6!8m2!3d-34.8733065!4d150.6061707"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="primary" bsPrefix="smg">
                      Get Directions <Icon prefix="fas" name="map-marker-alt" />
                    </Button>
                  </a>
                </li>
                <li className="nav-item mx-2 mb-3">
                  <Button
                    variant="primary"
                    bsPrefix="smg"
                    onClick={() => showContactForm("midcity")}
                  >
                    General Enquiry <Icon prefix="fas" name="envelope" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 text-center">
              <p className="details-heading">Contact Details</p>
              <p className="details-subheading">Phone</p>
              <p>(02) 4421 3666</p>
              <p className="details-subheading">Address</p>
              <p>Cnr Princes Highway &amp; North Street, Nowra, NSW</p>
            </div>
            <div className="col-12 col-lg-6 text-center">
              <p className="details-heading">Trading Hours</p>
              <p className="details-subheading">Monday - Friday</p>
              <p>8:30am - 5:00pm</p>
              <p className="details-subheading">Saturday</p>
              <p>8:30am - 2:30pm</p>
              <p className="details-subheading">Sunday</p>
              <p>Closed</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
